import { Container } from "react-bootstrap"
import { useState } from "react";
import SchoolCalendar from "../components/Calendar";
import SchoolClasses from "../components/Classes";
import ThemedButtonIcon from "../components/ThemedButtonIcon";
// import Calligraphy from "../assets/calligraphy.jpg"
import Library from "../assets/library.png";
import YCT from "../assets/YCT_logo.jpg";
import HSK from "../assets/HSK.png";
import KungFu from "../assets/KungFuClub.jpg";
import QiGong from "../assets/qigong.jpg";
import KungFuEnrollForm from "../assets/Files/RCS Kungfu enrollment form.pdf"
import Calligraphy1 from "../assets/others/calligraphy-1.jpg"
import Calligraphy2 from "../assets/others/calligraphy-2.jpg"
import Calligraphy3 from "../assets/others/calligraphy-3.jpg"
// import YCTForm from "../assets/Files/YCT_registration_form.pdf";
// import hskForm from "../assets/Files/HSK registration form.pdf";


function Courses(props) {
  const [items, setItems] = useState(props.init);

  // const location = useLocation();
  // const {subitem} =  location.state;
  // console.log(subitem);

  // setItems({subitem: true});

  function handleClick(e) {

    const name = e.target.name;
    setItems(() => {
      var itemsSelected = {
        calendar: false,
        classes: false,
        extra: false,
        yct: false,
        curriculum: false
      }
      itemsSelected[name] = true;
      return itemsSelected;
    })
  }


  return (
    <div>
      <Container fluid className="pageContainer">
        <div className="pageTitleDiv">COURSES</div>
        <Container fluid className="pageImageContainer">

        </Container>
        <Container className="pageDetails">
          <div className="pageMenuButtons">

            <Container style={{ height: "80px" }}>
              <p style={{ fontSize: "1.6rem", color: "white", textAlign: "left", fontWeight: "500" }}>  In this section</p>
            </Container>

            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"calendar"}
              disableElevation={true}
              showIcon={items["calendar"]}
              handlClick={handleClick}
              buttonText={"Calendar"}
            />

            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"classes"}
              disableElevation={true}
              showIcon={items["classes"]}
              handlClick={handleClick}
              buttonText={"Classes"}
            />
            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"curriculum"}
              disableElevation={true}
              showIcon={items["curriculum"]}
              handlClick={handleClick}
              buttonText={"Curriculum Plan"}
            />
            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"yct"}
              disableElevation={true}
              showIcon={items["yct"]}
              handlClick={handleClick}
              buttonText={"Chinese Proficiency Test"}
            />
            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"extra"}
              disableElevation={true}
              showIcon={items["extra"]}
              handlClick={handleClick}
              buttonText={"Extracurricular & Library"}
            />


          </div>
          <Container className="pageMenuDetails">
            <div className="sectionDiv" style={{ display: items["calendar"] ? " block" : "none " }} >
              <div style={{ textAlign: "center", margin: "20px" }}>
                <h2> 雷丁中文学校校历</h2>
                <h2> Reading Chinese School Term Dates</h2>
                <h2> 2024-2025</h2>
              </div>
              <SchoolCalendar />
            </div>

            <div className="sectionDiv" style={{ display: items["classes"] ? " block" : "none " }} >
              <div style={{ textAlign: "center", margin: "20px" }}>
                <h2> Reading Chinese School Classes</h2>
                <h2> 2024-2025</h2>
              </div>
              <SchoolClasses />
            </div>

            <div className="sectionDiv" style={{ display: items["curriculum"] ? " block" : "none ", textAlign: "center", padding: "50px", margin: "20px" }} >



              <h2>雷汀中文学校年度教学大纲<br />

                Yearly Curriculum Plan</h2>



              Click <a href="https://drive.google.com/drive/folders/1o7ea5WqRoqtWtmlwPv4f5tx2aRMWxm69" target="_blank" rel="noopener noreferrer">here</a> to find each class's yearly curriculum plan.

            </div>


            <div className="sectionDiv" style={{ display: items["extra"] ? " block" : "none " }} >

            <div style={{ textAlign: "center", padding: "20px", margin: "20px", border: " 1px solid grey" }}>
                <h2 style={{ marginBottom: "20px" }}> Chinese Calligraphy</h2>

                <img src={Calligraphy1} alt="Chinse Calligraphy" width={"95%"} />
                <br />
                <br />
                <img src={Calligraphy3} alt="Chinse Calligraphy" width={"95%"} />
                <br />
                <br />
                <img src={Calligraphy2} alt="Chinse Calligraphy" width={"95%"} />
                <br />
                <br />

              </div>

              <div style={{ textAlign: "center", padding: "20px", margin: "20px", border: " 1px solid grey" }}>
                <h2 style={{ marginBottom: "20px" }}>Kung Fu Club</h2>

                <img src={KungFu} alt="Kung Fu club" width={"95%"} />
                <br />
                <img src={QiGong} alt="Kung Fu club" width={"95%"} />
                <br />
                <br />
                <p>To enroll, please email the signed form to schoolclub@hulutang.org.uk</p>
                <a href={KungFuEnrollForm} target="_blank" rel="noopener noreferrer">Hulutang Activity Enrollment Form</a><br />
              </div>



              <div style={{ padding: "30px", margin: "20px", border: " 1px solid grey" }}>
                <h1 style={{ marginTop: "20px", textAlign: "center" }}>11+ Courses</h1>


                <h5> 11+ courses for Year 4 and Year 5 students. <br />
                  For more information, please contact Ms Tan by email: pohsiewt@live.co.uk</h5>
              </div>


              <div style={{ textAlign: "center", padding: "50px", margin: "20px", border: " 1px solid grey" }}>
                <img src={Library} alt="Library" height={"150px"} />
                <h5 style={{ marginTop: "20px" }}> Openning Time:</h5>
                <h5> Sunday 10:15 - 11:30</h5>
              </div>
            </div>

            <div className="sectionDiv" style={{ display: items["yct"] ? " block" : "none ", textAlign: "center", margin: "30px" }} >
              <img src={YCT} alt="YCT" width={"40%"} style={{ display: "inline" }} />
              <img src={HSK} alt="HSK" width={"40%"} style={{ display: "inline" }} />
              <h1 style={{ color: "black" }} >2024 YCT, HSK, HSKK Paper-based Test - Reading Chinese School</h1>

              <div style={{ textAlign: "left" }}>

                <br />
                <h4>Test Date: 19 May 2024 (Sunday)</h4>
                <h4>Exam Location: Language Building, Reading Chinese School</h4>
                <h4>Registration Deadline: 10 April 2024</h4>
                <p></p>
                <p></p>
                <h4 style={{ margin: "30px 0" }}>To register for the test, please submit the registration form online: <p></p>
                  <a href="https://forms.gle/8w1zKFZB2JNmgyJ96 " target="_blank" rel="noopener noreferrer">  https://forms.gle/8w1zKFZB2JNmgyJ96 </a></h4>
                <p></p>
                <p></p>
                <h2>Exam Fee (for students who take Written Test Only or both Written Test and Speaking Test)</h2>
                <p></p>
                <div style={{ marginTop: "10px", marginLeft: "30px" }}>
                  <h5>YCT1 written test  £15.00</h5>
                  <h5>YCT2 written test  £20.00</h5>
                  <h5>YCT3 written test  £30.00</h5>
                  <h5>YCT4 written test  £35.00</h5>
                  <h5>YCT Speaking Basic  £10.00</h5>
                  <h5>YCT Speaking Intermediate  £10.00</h5>
                  <h5>HSK1 written test   £25.00</h5>
                  <h5>HSK2 written test   £35.00</h5>
                  <h5>HSK3 written test   £45.00</h5>
                  <h5>HSK4 written test   £55.00</h5>
                  <h5>HSK5 written test   £65.00</h5>
                  <h5>HSK6 written test   £75.00</h5>
                  <h5>HSKK Basic  £25.00</h5>
                  <h5>HSKK Intermediate £35.00</h5>
                  <h5>HSKK Advanced   £45.00 </h5>
                </div>
                <p></p>
                <p></p>
                <h2>Exam Fee (for students who take Speaking Test Only)</h2>
                <div style={{ marginTop: "10px", marginLeft: "30px" }}>
                  <h5>YCT Speaking Basic £20.00</h5>
                  <h5>YCT Speaking Intermediate £20.00</h5>
                  <h5>HSKK Basic £35.00</h5>
                  <h5>HSKK Intermediate £45.00</h5>
                  <h5>HSKK Advanced   £55.00 </h5>
                </div>
                <p></p>
                <p></p>
                <h2>Payments to be made to:</h2>
                <div style={{ marginTop: "10px", marginLeft: "30px" }}>
                  <h5>Reading Chinese School<br />
                    Bank: Lloyds Bank<br />
                    Sort Code: 30-96-96<br />
                    Account Number: 01402495<br />
                    <p></p>
                    For Reading Chinese School students, please use "class name" + "student name" + "test module" as payment reference.  <p></p>For non-Reading Chinese School students, please use "student name" + "test module" as payment reference.</h5>

                </div>
              </div>
              {/* <h4 style={{ marginBottom: "20px", marginTop: "20px" }}> HSK,HSKK,YCT报名表【雷丁中文学校】<br /> <br/>

                <a href={YCTForm} target="_blank"  rel="noopener noreferrer">YCT Application Form 报名表</a><br />
                <p></p>
                <a href={hskForm} target="_blank"  rel="noopener noreferrer">HSK Application Form 报名表</a><br /></h4> */}


            </div>
          </Container>



        </Container>
      </Container>
    </div >
  );
}

export default Courses;
