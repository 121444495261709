import { Container } from "react-bootstrap";
import { useState } from "react";
import ThemedButtonIcon from "../components/ThemedButtonIcon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import DataProtectionPolicy from "../assets/Files/RCS-Data Protection Policy.pdf";
import SafeGuardingPolicy from "../assets/Files/Safeguarding-and-Child-Protection-Policy-September-2022-23_RCS_v.1.pdf";
import SafeGuradingForm from "../assets/Files/SafeguardingIncident-Record-Form.doc";
import KCSIE from "../assets/Files/KCSIE_2022_Part_One.pdf"
import wechatLogo from "../assets/RCS_Wechat_QRcode.png";

function About(props) {

  //const [items, setItems] = useState({ profile: true });
  const [items, setItems] = useState(props.init);

  function handleClick(e) {

    const name = e.target.name;
    setItems(() => {
      var itemsSelected = {
        profile: false,
        // safegurdingPolicy: false,
        // dataProtectionPolicy: false,
        policy: false,
        organization: false,
        schoolRules: false

      }
      itemsSelected[name] = true;
      return itemsSelected;
    })
  }

  return (
    <div>
      <Container fluid className="pageContainer">
        <div className="pageTitleDiv">ABOUT US</div>
        <Container fluid className="pageImageContainer">

        </Container>
        <Container className="pageDetails">
          <div className="pageMenuButtons">
            {/* <div className="subMenuButtons">
               <ThemeProvider theme={theme}>
                <Button variant="contained" name = {"profile"} disableElevation={true} className="buttonStyle mb-5 " 
                endIcon={items["profile"]? <ChevronRightIcon /> : null} 
                onClick={handleClick}
                onMouseOver={handleClick}> More About Us</Button>
              </ThemeProvider> 

            </div> */}

            <Container style={{ height: "80px" }}>
              <p style={{ fontSize: "1.6rem", color: "white", textAlign: "left", fontWeight: "500" }}> In this section</p>
            </Container>

            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"profile"}
              disableElevation={true}
              showIcon={items["profile"]}
              handlClick={handleClick}
              buttonText={"School Profile"}
            />

            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"policy"}
              disableElevation={true}
              showIcon={items["policy"]}
              handlClick={handleClick}
              buttonText={"School Policy"}
            />
            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"organization"}
              disableElevation={true}
              showIcon={items["organization"]}
              handlClick={handleClick}
              buttonText={"Organisation"}
            />
            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"schoolRules"}
              disableElevation={true}
              showIcon={items["schoolRules"]}
              handlClick={handleClick}
              buttonText={"School Rules"}
            />

          </div>
          <Container className="pageMenuDetails" style={{ marginTop: "20px" }} >

            {/* <div style={{ visibility: items["profile"] ? 'visible' : 'hidden' }} > */}
            <div className="sectionDiv" style={{ display: items["profile"] ? " block" : "none " }} >
              <h1 style={{ marginBottom: "20px" }}>学校概况 School Profile</h1>
              <p>
                雷丁中文学校成立于1978年，我们的宗旨是向雷丁地区所有想学习了解中文和中华文化的人教授普通话以及中华文化。
              </p>

              <p>
                The Reading Chinese School was established in 1978. The school's aim is to provide education in both Chinese language and culture for children and adults of all ages and backgrounds.
              </p>

              <p>
                雷丁中文学校欢迎三岁以上的所有学生加入这个美妙的中国语言文化学习旅程.
              </p>
              <p>
                Reading Chinese School welcome students from all level (ages from 3 years old) to join our fantastic Chinese language and cultural learning journey.
              </p>
              <p>
                雷丁中文学校提供适合三岁以上的中文课程，另外还有适合成人和儿童的多种传统文化课程，例如： 唱歌、跳舞、艺术、朗诵等等。家长会还组织蛋糕义卖以及新年贺卡比赛等各种活动。
              </p>
              <p>
                We also offer Chinese traditional art, dance, singing, speech & recital for both children and adults. PTA additionally organises various activities such as cake sales, New Year card design competition.
              </p>

              <div className="lessonTime">
                <div >

                  <p >上课时间:<br /> 逢周日 10：00-12：00</p>

                  <p >Lesson time: <br />every Sunday 10:00-12:00</p>
                  <p>Address 地址:<br />Highdown School<br />Surley Row, Emmer Green<br />Reading RG4 8LR</p>


                </div>

                <img src={wechatLogo} width={"250px"} alt="Wechat Logo" min-height="150px"></img>

              </div>
              <p> For further information, please contact: <br />


                联系人: 刘彦校长<br />

                Dr Yan Liu (Head Teacher)<br />

                Mobile: 07827961884<br />

                Email: readingchineseschool@hotmail.com<br />
              </p>


            </div>

            <div className="sectionDiv" style={{ display: items["policy"] ? " block" : "none " }} >
              <p style={{ fontSize: "1.2rem" }}>Safeguarding Policy</p>
              <a href={KCSIE} target="_blank" rel="noopener noreferrer">Keeping children safe in education 2022</a><br /><br />
              <a href={SafeGuardingPolicy} target="_blank" rel="noopener noreferrer">Safeguarding and Child Protection Policy</a><br /><br />

              <a href={SafeGuradingForm} target="_blank" rel="noopener noreferrer">Incident Record Form</a>

              {/* <a 
              href="https://drive.google.com/drive/folders/1DoGY-AlbqzGqgrU51yC4ESi9UrU-vdkB" target="_blank" rel="noopener noreferrer"> Safegurding Policy</a> */}
              <p></p>
              <p></p>
              <p></p>
              {/* <a href={DataProtectionPolicy} target="_blank"  rel="noopener noreferrer">Data Protection Policy</a><br /> */}

            </div>

            <div className="sectionDiv" style={{ display: items["organization"] ? "block" : "none" }} >


              <Container>
                <h2 style={{ textAlign: "center" }}>Major Roles<br />主要职员</h2>
                <Row  /*xs={2} md={4} lg={6}*/>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Head&nbsp;Teacher</h5>
                      <p></p>
                      <h6>Dr Yan Liu</h6>
                      <h6>刘彦博士</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Deputy Head&nbsp;Teacher</h5>
                      <p></p>
                      <h6>Dr Ganggang Zhang</h6>
                      <h6>张纲纲博士</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>School Committee Secretary</h5>
                      <p></p>
                      <h6>Erika Wan</h6>
                      <h6>温玉霞</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Treasurer</h5>
                      <p></p>
                      <h6>Junling He</h6>
                      <h6>贺俊玲</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Academic&nbsp;Lead</h5>
                      <p></p>
                      <h6>Hongmin Jiang</h6>
                      <h6>姜红敏</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Extra-curriculum Lead</h5>
                      <p></p>
                      <h6>Yongqin Liu</h6>
                      <h6>刘永勤</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>

                      <h5>First&nbsp;Aid <br />Group Lead</h5>
                      <p></p>
                      <h6>Hong Liu</h6>
                      <h6>刘虹</h6>
                    </div>
                  </Col>

                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>

                      <h5>IT Lead</h5>
                      <p></p>
                      <h6>Yanrong Zhang</h6>
                      <h6>张艳荣</h6>
                    </div>
                  </Col>

                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>PTA Chair</h5>
                      <p></p>
                      <h6>Gigi Akiko</h6>
                      <h6>秋子</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Safeguarding Officer</h5>
                      <p></p>
                      <h6>Jinyan Geng</h6>
                      <h6>耿金燕</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5>Safeguarding Officer</h5>
                      <p></p>
                      <h6>Lei Zhang</h6>
                      <h6>张蕾</h6>
                    </div>
                  </Col>
                  <Col style={{ minWidth: "220px", margin: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5> </h5>
                      <p> </p>
                      <h6> </h6>
                      <h6> </h6>
                    </div>
                  </Col>

                </Row>

              </Container>



            </div>

            <div className="sectionDiv" style={{ display: items["schoolRules"] ? " block" : "none " }} >
              <h1>School Rules</h1>
              <p>1. Children should arrive at school on time, and teachers arrive at school at least 15 minutes earlier.</p>
              <p>2. Children are requested to register with the class teacher after arriving at school, i.e. the teacher takes attendance registration. Children need to get permission from the teacher when leaving before finish school.</p>
              <p>3. Children should inform the class teacher at least one day before the class, where possible, if he/she cannot attend the class due to illness or other reasons.</p>
              <p>4. Children are responsible for their own clothes and properties during the time at the school. Teachers will only help to look after possessions for the beginners’ class.</p>
              <p>5. Eating and drinking are not allowed during the class time, unless you have health-related issues and parents should inform the teacher beforehand.</p>
              <p>6. Children should not leave their seats the classroom without the permission of the teacher.</p>
              <p>7. Children should cooperate with the teacher, no irrelevant talking or activities; teacher is responsible to manage the school class discipline and study.</p>
              <p>8. Children ought to be kind and helpful to each other; teachers and children should be respectful to each other.</p>
              <p>9. Teachers should communicate with parents regularly, to inform parents their children’s progress and discuss any issues relevant to the teaching.</p>
              <p>10. Parents should support the school’s teaching activities and other culture events, for example, to check and sign on children’s homework.</p>
              <p>11. Speak quietly in the school corridor, and do not make loud noisy, affecting others.</p>
              <p>12. Do not running around and chasing each other in the classroom and in the corridor.</p>
              <p>13. Do not climb the handrails and the railings on the stairs.</p>
              <p>14. If the classroom desks and chairs are dotted with ink and paint, please ensure to clean it up.</p>
              <p>15. Remind children to pick up the ground residues of food, litter, and pieces of paper etc., and thrown them into the bin.</p>
              <p>16. Anything written on the whiteboard must be wiped out before leaving.</p>
              <p>17. Children are not allowed to touch the Audio-visual equipment and other apparatus in the classrooms.</p>
              <p>18. Children are prohibited to climb the windows and get onto the building roof.</p>
              <p>19. The class teacher should be the last person leaving the classroom. Before leaving, make sure the tables and chairs are back to the original numbers and layouts, switch off the equipment, the light, and close the windows.</p>
              <p>20. Respect the School and its environment. Children, teachers, committee members and parents should work together to foster a good environment for teaching and learning and to maintain the good credibility of the school.</p>

              <h1>Teacher Work Requirement</h1>
              <p>1. Willing to attend various trainings and seminars, e.g. Safeguarding Children Training and teachers’ training; obey school policies and rules.</p>
              <p>2. Prepare Work of Scheme for a whole academic year, referring to school syllabus, textbook and Edexcel examination requirements.</p>
              <p>3. Provide literary, cultural references and extra reading materials, assisting the teaching.</p>
              <p>4. Lecture preparation with a written session plan for every weekly teaching, clearly stating teaching aims, objectives, teaching methods and teaching resources.</p>
              <p>5. Arrive at least 15 minutes early to prepare the teaching resources, arrange the sitting plan, and ensure the comfortable environment in the classroom.</p>
              <p>6. Maintain classroom and class discipline.</p>
              <p>7. Chinese computer input, printing and copying. Prepare students’ homework and writings.</p>
              <p>8. Praise students with sandwiched “praise-criticise-praise”, giving constructive criticisms.</p>
              <p>9. Carry out continuous progress assessments, both informal and formal, motivating learners.</p>
              <p>10. Give feedback to students and communicating with students and parents.</p>
              <p>11. Continuous self-improvement and carry out subject-related research.</p>
              <p>12. Attend school teachers' meetings and assist with the school cultural activities.</p>
              <p>13. Understand the nature of the school, i.e. a voluntary organisation, and willing to take on extra work that is requested by the school management committee.</p>
              <p>14. In order to maintain good quality of teaching, all teachers have to teach in the school minimum 1 year. Give the school minimum 3 months’ notice when leaving.</p>

              <h1> 学校校规</h1>
              <p>1. 学生要按时，准时到校上课。老师应至少提前15分钟到校。</p>
              <p>2. 学生到校后应由任课老师点名报到。由任课老师宣布下课后方能离校。若提前离校，必须有任课老师的认可同意。</p>
              <p>3. 学生因病或其它事宜不能前来上课，应尽可能提前通知学校或任课老师，做出相应安排。</p>
              <p>4. 在校期间，学生自己的衣物应由自己负责保管。任课老师可以协助照看初级班同学的衣物。</p>
              <p>5. 不得在课上及图书馆内吃喝。</p>
              <p>6. 未经老师允许，不可擅自离开座位和教室。</p>
              <p>7. 学生要认真听讲，配合教师授课，不可做与学习内容无关的谈话及行为。老师全权负责教室的纪律和学习。</p>
              <p>8. 同学之间要友爱团结，学生与老师之间要相互尊重。</p>
              <p>9. 老师应及时与家长沟通和交流，通报学生的学习进展，讨论与教学有关的问题。老师至少一年一次给出班级学生的学习进展报告。</p>
              <p>10. 家长要配合学校的教学及各项活动，例如：督促检查学生完成作业等。</p>
              <p>11. 不得在过道里大声喧哗，影响他人。</p>
              <p>12. 不得在教室里，过道里跑动，追逐，以免给自己即他人带来安全隐患。</p>
              <p>13. 不得攀爬树，楼梯扶手，栏杆,学生柜子和窗户等。</p>
              <p>14. 如在使用过程中，给教室的桌椅上涂上污迹，如墨水，颜料等，请清洗干净。</p>
              <p>15. 提醒孩子们自己把掉在地上的食物渣，屑，纸片等捡起来，扔在垃圾桶内。</p>
              <p>16. 除老师之外，其他人不得动用教室里的电教设备和仪器。</p>
              <p>17. 不得在黑板上书写与上课无关的东西。老师离开教室之前，擦去黑板上所有的书写内容。</p>
              <p>18. 老师最后一个离开教室。离开时请复原桌椅的排列，关掉设备，关灯，关好窗户。</p>
              <p>19. 所有在校学生，老师，委员会成员以及家长应该通力合作，提供一个良好教与学的环境，维护学校的声誉。</p>

              <h1> 教师守则</h1>
              <p>1. 热爱学生和教学工作；认真阅读，领会并遵守学校的各项制度，特别是“隐私保密制度”和“儿童保护条例”。</p>
              <p>2. 根据教学大纲按时备好学年，学期及课时计划。并将学年，学期计划各交一份给学校.</p>
              <p>3. 使用中文学校指定的教材，教师可根据本班学生实际情况和教学需要适当补充参考资料.</p>
              <p>4. 书写每堂课的教学计划，标明这节课的教学目的，手段，教学方法和教具。 认真上好每一堂课；认真批改学生的每一份作业.</p>
              <p>5. 至少提前十五分钟到，按教学需要摆好课桌椅，准备好教具材料，并确保教室的舒适环境。</p>
              <p>6. 负责所教班级学生在校期间的安全，卫生既保持所用教室的卫生. 维持正常的课堂教学秩序。</p>
              <p>7. 给学生布置家庭作业。 保管好所借用的学校的书籍，财物等，离职时须及时退还.</p>
              <p>8. 多鼓励学生，给予有建设性的批评。 给每个学生均等的机会，特别是要鼓励那些性格内向，不善言辞的学生积极发言和参与，不能只面向学习好的或老师喜欢的学生.</p>
              <p>9. 仔细观察学生， 不断地评估学生的进展情况，激发学生学习的兴趣。</p>
              <p>10. 尊重学生和家长，多与家长进行交流；虚心听取学生及家长的意见和建议，并及时向上反映.</p>
              <p>11. 不断提高自身素质和业务水平。 同事之间互相尊重，互相配合，要有一良好的团队合作精神</p>
              <p>12. 参加学校召开的每次会议和培训；参加学校组织的每项活动，编排节目，统计人数等；学生的管理，通知，信息等的分发。</p>
              <p>13. 为了学校师资力量的稳定，任课老师必须教满一学年方可提出辞职，并提前三个月给学校通知。</p>

            </div>
          </Container>

        </Container>
      </Container>
    </div>
  );
}

export default About;
