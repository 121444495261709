// import Table from 'react-bootstrap/Table';
import TermDates from "../assets/others/term-dates_2.png"

function SchoolCalendar() {
    return (
        <div style={{ textAlign: "center", padding: "20px 10px", margin: "30px 30px 10px 10px", border: " 1px solid grey" }}>

            <img src={TermDates} alt="Term Dates" width={"100%"} />
        </div>

        // <Table striped>
        //     <thead>
        //         <tr>
        //             <th>Week No.</th>
        //             <th>Date</th>

        //         </tr>
        //     </thead>
        //     <tbody>
        //         <tr>
        //             <td>Week 1</td>
        //             <td>10/09/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 2</td>
        //             <td>17/09/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 3</td>
        //             <td>24/09/2023</td>
        //         </tr>
        //         <tr><td>Week 4</td>
        //             <td>01/10/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 5</td>
        //             <td>08/10/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 6</td>
        //             <td>15/10/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 7</td>
        //             <td>22/10/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Half Term</td>
        //             <td>29/10/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 8</td>
        //             <td>05/11/2023</td>
        //         </tr>
        //         <tr><td>Week 9</td>
        //             <td>12/11/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 10</td>
        //             <td>19/11/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 11</td>
        //             <td>26/11/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 12</td>
        //             <td>03/12/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 13</td>
        //             <td>10/12/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Christmas Break</td>
        //             <td>17/12/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Christmas Break</td>
        //             <td>24/12/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Christmas Break</td>
        //             <td>31/12/2023</td>
        //         </tr>
        //         <tr>
        //             <td>Week 14</td>
        //             <td>07/01/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 15</td>
        //             <td>14/01/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 16</td>
        //             <td>21/01/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 17</td>
        //             <td>28/01/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 18</td>
        //             <td>04/02/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 19</td>
        //             <td>11/02/2024</td>
        //         </tr>
        //       <tr>
        //             <td>Half Term</td>
        //             <td>18/02/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 20</td>
        //             <td>25/02/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 21</td>
        //             <td>03/03/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 22</td>
        //             <td>10/03/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 23</td>
        //             <td>17/03/2024</td>

        //         </tr>
        //         <tr>
        //             <td>Week 24</td>
        //             <td>24/03/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Easter Break</td>
        //             <td>31/03/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Easter Break</td>
        //             <td>07/04/2024</td>
        //         </tr>   
        //         <tr>
        //             <td>Easter Break</td>
        //             <td>14/04/2024</td>
        //         </tr>             
        //         <tr>
        //             <td>Week 25</td>
        //             <td>21/04/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 26</td>
        //             <td>28/04/2024</td>
        //         </tr>
        //         <tr>
        //             <td>Week 27</td>
        //             <td>05/05/2024</td>
        //         </tr>      
        //         <tr>
        //             <td>Week 28</td>
        //             <td>12/05/2024</td>
        //         </tr>    
        //         <tr>
        //             <td>Week 29</td>
        //             <td>19/05/2024</td>
        //         </tr>    
        //         <tr>
        //             <td>Week 30</td>
        //             <td>26/05/2024</td>
        //         </tr>     
        //         <tr>
        //             <td>Half Term</td>
        //             <td>02/06/2024</td>
        //         </tr>   
        //         <tr>
        //             <td>Week 31</td>
        //             <td>09/06/2024</td>
        //         </tr>   
        //         <tr>
        //             <td>Week 32</td>
        //             <td>16/06/2024</td>
        //         </tr> 
        //         <tr>
        //             <td>Week 33</td>
        //             <td>23/06/2024</td>
        //         </tr> 
        //         <tr>
        //             <td>Week 34</td>
        //             <td>30/06/2024</td>
        //         </tr>  
        //         <tr>
        //             <td>Week 35</td>
        //             <td>07/07/2024</td>

        //         </tr>                                                                                                              
        //     </tbody>
        // </Table>
    );
}

export default SchoolCalendar;